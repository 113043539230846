.headerContainer {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
}
.imgDiv{
    height: 100px;
    width: 100px;
    padding-top:40px;
    margin-left: 4%;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 769px){
    .headerContainer {
        height: 70px!important;
        width: 100%;
        justify-content: center!important;
        position: static!important;
    }
    .imgDiv {
        height:70px;
        width:70px;
        padding-top:0px;
        margin-left: unset!important;
    }
}