.main{
    .MuiListItem-root{
        padding-bottom: 0px;
    }
    .MuiOutlinedInput-root{
        input{
            font-family: 'poppins';
        }
    }
    .submit{
        padding: 4px 15px;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
        min-width:200px;
        font-weight:400;
        border-radius: 60px;
        text-transform: initial;
        color:#ffffff;
        font-size:20px;
        background: #05c8c6;
        margin-top:20px;
        margin-bottom: 20px;;
    }
    .MuiOutlinedInput-input{
        font-family:'poppins';
        outline: none;
        &::focus{
            outline: 0;
        }
    }
    .MuiOutlinedInput-notchedOutline{
        border-color:rgba(0, 0, 0, 0.23)!important;
    }
    .title{
        text-align: left;
        font-size:40px;
        line-height:40px;
    }
}

.box{
    box-shadow: 0 3px 20px hsl(0deg 0% 84% / 35%);
    box-sizing: border-box;
    grid-column: 1/span 1;
    border-radius: 20px;
    flex-direction: column;
    background-color: #fff;
}
.loading-outer{
    padding: 4px 15px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    width: 250px;
    font-weight: 400;
    height:43px;
    border-radius: 60px;
    text-transform: initial;
    color: #ffffff;
    font-size: 20px;
    background: #05c8c6;
    margin-top: 20px;
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 800px){
    .main{
        .title{
            text-align: center;
            font-size:30px;
            line-height:30px;
        }
    }
    .loading-outer{
        width:100%;
    }
}